<mat-toolbar>
    <button mat-icon-button class="example-icon d-sm-none" aria-label="Example icon-button with menu icon" (click)="sidebarToggle()">
        <mat-icon>menu</mat-icon>
    </button>

    <span class="flex-grow-1"></span>

    <mat-list class="d-flex align-items-center p-0" role="list">
        <mat-list-item role="listitem">
            <button mat-button class="main-btn text-black font-18" (click)="changeLanguage()">
                <div class="d-flex align-items-center">
                    <svg class="m-inline-end-2" xmlns="http://www.w3.org/2000/svg" fill="none" width="18" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>

                    <span class="font-english" [ngClass]="{'font-arabic': lang === 'en'}">{{ lang === 'en' ? 'العربية' : 'English'}}</span>
                </div>
            </button>
        </mat-list-item>

        <mat-list-item role="listitem">
            <button mat-button class="main-btn text-black font-18"  (click)="logOut()">
                <div class="d-flex align-items-center">
                    <svg class="m-inline-end-2" xmlns="http://www.w3.org/2000/svg" fill="none" width="18" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>

                    <span style="width: max-content;">{{ 'LOGOUT' | translate}}</span>
                </div>
            </button>
        </mat-list-item>
    </mat-list>
</mat-toolbar>
