<main class="d-flex">
    <app-sidebar></app-sidebar>

    <section class="d-flex flex-column flex-grow-1">
        <app-navbar></app-navbar>

        <section class="main-page d-flex flex-column flex-grow-1 position-relative">
            <router-outlet></router-outlet>
        </section>
    </section>
</main>
