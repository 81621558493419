<main class="main-container" *ngIf="isLoading">
    <div class="spinner__container spinner__effect--8">
      <ul class="spinner">
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
        <li class="spinner__square"></li>
      </ul>
    </div>
</main>
